




























































































































































































































































/* eslint-disable @typescript-eslint/naming-convention */
import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
import { deepClone } from '@/utils';
import {
  shoppingMallArrival,
  consumeAbility,
  differentAgeConsumeDetails,
  marketPenetration,
} from '@/utils/echarts/echarts';
import {
  mallTotalFlow,
  portraitByMallId,
  perAgeNumTheSum,
} from '@/services/api';
import {
  TotalPassengerFlowParams,
  PerAgePortraitSParams,
  PerAgeNumTheSumParams,
} from '@/types/store';
import tdesignFooter from '@/components/GlobalFooter/index.vue';

@Component({
  components: {
    compareCard,
    portraitPrivcar,
    tdesignFooter,
  },
})
export default class PortraitEconomy extends Vue {
  $dayjs: any;
  slotEcharts = true;
  slotcontent = false;
  show = true;
  show1 = true;
  show2 = true;
  show3 = true;
  show4 = true;
  show5 = true;
  show6 = true;
  dataStatus0 = 0;
  dataStatus1 = 0;
  dataStatus2 = 0;
  dataStatus3 = 0;
  dataStatus4 = 0;
  dataStatus5 = 0;
  dataStatus6 = 0;
  nodeDataText = '数据量过低，由于数据统计样本失衡及用户数据隐私策略保护，画像标签不展示。';
  order = true;
  reset = false;
  excelArr: any = [];
  displayData = [{}];
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  consumeLevel = {};
  shoppingMallArrival = {};
  consumeAbility = {};
  differentAgeConsumeDetails = {};
  lifeStage = {};
  maritalStatus = {};
  childStatus = {};
  education = {};
  mallTotal = 0;
  mallTotalStatus = false;
  pravcar = Vue.prototype.$GLOBAL.privacyInfo;
  $beaconAction: any;

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }
  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.show = true;
    this.show1 = true;
    this.show2 = true;
    this.show3 = true;
    this.show4 = true;
    this.show5 = true;
    this.show6 = true;
    this.mall_id = this.$store.state.mall.mallId;
    this.date = this.$store.state.mall.date;
    this.duration = this.$store.state.mall.duration;

    const mallTotalFlowParams: TotalPassengerFlowParams = {
      mall_id: this.mall_id,
      duration: this.duration,
      date: this.date,
      type: 1,
      property: 1,
    };
    const mallTotalRes = await mallTotalFlow(mallTotalFlowParams);
    this.mallTotal = mallTotalRes.sum;
    this.mallTotalStatus = this.mallTotal < 100;
    if (this.mallTotalStatus) {
      return;
    }
    // 年龄分布
    const PerAgePortraitSParams: PerAgePortraitSParams = {
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
      type: 102,
      j_type: 1,
      sum_type: 0,
    };
    const title = '年龄';
    const agePortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title,
      '',
      shoppingMallArrival,
      [''],
    );
    this.shoppingMallArrival = agePortraitRes.option;
    this.show = false;
    // 性别
    PerAgePortraitSParams.type = 101;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry = ['#0062ff', '#ee5c3d'];
    const title1 = '性别';
    const title1s = '男女性别占比';
    const sexPortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title1,
      title1s,
      consumeAbility,
      colorAry,
    );
    this.consumeAbility = sexPortraitRes.option;
    this.show1 = false;
    this.dataStatus3 = sexPortraitRes.dataStatus;
    // 人生阶段
    PerAgePortraitSParams.type = 106;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry2 = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title2 = '人生阶段';
    const title2s = '人生阶段占比';
    const portraitRes1 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title2s,
      consumeAbility,
      colorAry2,
    );
    this.lifeStage = portraitRes1.option;
    this.show4 = false;
    this.dataStatus4 = portraitRes1.dataStatus;
    // 婚姻状态
    PerAgePortraitSParams.type = 105;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry3 = colorAry;
    const title3 = '婚姻状态';
    const title3s = '婚姻状态占比';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      consumeAbility,
      colorAry3,
    );
    this.maritalStatus = portraitRes2.option;
    this.show5 = false;
    this.dataStatus5 = portraitRes2.dataStatus;
    // 子女状态
    PerAgePortraitSParams.type = 104;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry4 = colorAry2;
    const title4 = '子女状态';
    const title4s = '子女年龄占比';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title4s,
      consumeAbility,
      colorAry4,
    );
    this.childStatus = portraitRes4.option;
    this.show6 = false;
    this.dataStatus6 = portraitRes4.dataStatus;
    // 学历
    PerAgePortraitSParams.type = 103;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title5 = '学历';

    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title5,
      title1s,
      shoppingMallArrival,
      [''],
    );
    portraitRes5.datas.reverse();
    this.education = portraitRes5.option;
    this.show3 = false;
    this.dataStatus3 = portraitRes5.dataStatus;
    // this.differentSexConsumeDetails = differentSexConsumeDetails();

    // 客群性别年龄人数分布
    // perAgeNumTheSum
    const params: PerAgeNumTheSumParams = {
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
      num_type: 20,
      pop_type: 21,
    };
    this.reset = false;
    const ageNumRes = await this.getperAgeNumTheSum(params);

    this.differentAgeConsumeDetails = ageNumRes.option;
    this.show2 = false;
    this.dataStatus2 = ageNumRes.dataStatus;
    // this.dataStatus3 = portraitRes5.dataStatus;
    this.excelArr = [
      agePortraitRes.datas,
      sexPortraitRes.datas,
      portraitRes1.datas,
      portraitRes2.datas,
      portraitRes5.datas,
      portraitRes4.datas,
      ageNumRes.datas,
    ];
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  async getperAgeNumTheSum(params: PerAgeNumTheSumParams): Promise<any> {
    const res = await perAgeNumTheSum(params);
    const { data, datas } = res;
    this.displayData = res;
    const { manData, menData, texts } = data;
    const option = differentAgeConsumeDetails(texts, manData, menData, 1);
    // 敏感值处理
    let hasOuthorData = false;
    let dataStatus = 0; // 0:正常 1:数据中包含0  2:数据中全是0或100
    const allData = [...manData, ...menData];
    for (const percent of allData) {
      if (percent !== 0 && percent !== 100) {
        hasOuthorData = true;
      }
      if (percent === 0) {
        dataStatus = 1;
      }
    }
    if (!hasOuthorData) {
      dataStatus = 2;
    }
    return {
      option,
      datas,
      dataStatus,
    };
  }

  async getPerAgePortrait(
    params: PerAgePortraitSParams,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await portraitByMallId(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    // 敏感值处理
    let hasOuthorData = false;
    let dataStatus = 0; // 0:正常 1:数据中包含0  2:数据中全是0或100
    for (const percent of percents) {
      if (percent !== 0 && percent !== 100) {
        hasOuthorData = true;
      }
      if (percent === 0) {
        dataStatus = 1;
      }
    }
    if (!hasOuthorData) {
      dataStatus = 2;
    }
    return {
      option,
      datas,
      dataStatus,
    };
  }
  inOrder(): void {
    this.reset = !this.reset;
    if (this.reset) {
      const displaypercent = deepClone(this.displayData);
      const displaypercentTwo = deepClone(this.displayData);
      this.orderDataRender(displaypercent, displaypercentTwo, 'num_percent');
      this.differentAgeConsumeDetails = marketPenetration(
        displaypercent.datas.map((i: any) => i.text),
        displaypercentTwo.datas.map((i: any) => i.num_percent),
        displaypercent.datas.map((i: any) => i.num_percent),
        '男性占比',
        '女性占比',
        '市场渗透率(%)',
      );
    } else {
      this.differentAgeConsumeDetails = differentAgeConsumeDetails(
        (this.displayData as any).data.texts,
        (this.displayData as any).data.manData,
        (this.displayData as any).data.menData,
        1,
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  orderDataRender(val1: any, val2: any, sortVal: any): void {
    val1.datas.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val2.datas.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val1.datas.forEach((i: any) => {
      if (i.text.includes('男')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
    val2.datas.forEach((i: any) => {
      if (i.text.includes('女')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
  }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.pageHandler();
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const datas = [];
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      const dateObj = {
        d: this.date,
        xw: `${this.$dayjs(this.date).subtract(6, 'day')
          .format('YYYY-MM-DD')}~${this.date}`,
        xm: this.$dayjs(this.date).format('YYYY-MM'),
      };
      this.excelArr.forEach((i: any) => {
        i.forEach((j: any) => {
        // eslint-disable-next-line no-param-reassign
          j.time = (dateObj as any)[this.duration];
        });
      });
      datas.push({
        sheetData: this.excelArr[0],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '年龄分布',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '年龄分布', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[1],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '性别',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '性别', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[2],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '人生阶段',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '学历', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[3],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '婚姻状态',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '婚姻状态', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[4],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '学历',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '学历', '占比'],
        columnWidths: [10, 8, 8],
      });
      // datas.push({
      //   sheetData: this.excelArr[5],
      //   sheetMultiHeader: [
      //     ['数据项:客流画像-自然属性'],
      //   ],
      //   sheetName: '子女状态',
      //   sheetFilter: ['time', 'text', 'percent'],
      //   sheetHeader: ['日期', '子女状态', '占比'],
      //   columnWidths: [10, 8, 8],
      // });
      datas.push({
        sheetData: this.excelArr[6],
        sheetMultiHeader: [
          ['数据项:客流画像-自然属性'],
        ],
        sheetName: '性别年龄人数分布',
        sheetFilter: ['time', 'text', 'num_percent'],
        sheetHeader: ['日期', '性别年龄分布', '占比'],
        columnWidths: [10, 10, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
}
